import { styled } from "@mui/system";
import { WhmStack } from "@securitize/reactjs-whm";

const TwoColsInputsContainerBase = styled(WhmStack)({
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  gap: "1rem",
});

const ThreeColsInputsContainerBase = styled(WhmStack)({
  display: "grid",
  gridTemplateColumns: "1fr 1fr 1fr",
  gap: "1rem",
});

export function TwoColsInputsContainer(
  props: Parameters<typeof TwoColsInputsContainerBase>[0],
) {
  return (
    <TwoColsInputsContainerBase
      {...props}
      sx={{
        display: {
          xs: "flex",
          md: "grid",
        },
        flexDirection: {
          xs: "column",
          md: "unset",
        },
      }}
    />
  );
}

export function ThreeColsInputsContainer(
  props: Parameters<typeof ThreeColsInputsContainerBase>[0],
) {
  return (
    <ThreeColsInputsContainerBase
      {...props}
      sx={{
        display: {
          xs: "flex",
          md: "grid",
        },
        flexDirection: {
          xs: "column",
          md: "unset",
        },
        gridTemplateColumns: {
          xs: "unset",
          sm: "1fr 1fr",
          lg: "1fr 1fr 1fr",
        },
      }}
    />
  );
}
