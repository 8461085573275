import { WhmTextField } from "@securitize/reactjs-whm";
import CountrySelectInput from "./CountrySelectInput";
import StateSelectInput from "./StateSelectInput";
import { useFormikContext } from "formik";
import { ThreeColsInputsContainer } from "../InputContainers";
import useTranslations from "../../../../hooks/useTranslations";
import { translationKeys } from "../../../../contexts/I18nContext";

type Props = {
  disabled?: boolean;
};

function AddressInputs({ disabled }: Props) {
  const { getTranslation: t } = useTranslations();
  const formik = useFormikContext<{
    address1: string;
    address2: string;
    city: string;
    postalCode: string;
    country: string;
  }>();

  return (
    <ThreeColsInputsContainer
      sx={{
        gridTemplateRows: {
          xs: "unset",
          md: '"1fr 1fr"',
        },
      }}
    >
      <WhmTextField
        required
        disabled={disabled}
        id="address1"
        name="address1"
        label={t(translationKeys.FORM_SECTION_ADDRESS_STREET)}
        value={formik.values.address1}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.address1 && Boolean(formik.errors.address1)}
        helperText={formik.touched.address1 && formik.errors.address1}
      />
      <WhmTextField
        disabled={disabled}
        id="address2"
        name="address2"
        label={t(translationKeys.FORM_SECTION_ADDRESS_ADDITIONAL)}
        value={formik.values.address2}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.address2 && Boolean(formik.errors.address2)}
        helperText={formik.touched.address2 && formik.errors.address2}
      />
      <WhmTextField
        required
        disabled={disabled}
        id="city"
        name="city"
        label={t(translationKeys.FORM_SECTION_ADDRESS_CITY)}
        value={formik.values.city}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.city && Boolean(formik.errors.city)}
        helperText={formik.touched.city && formik.errors.city}
      />
      <WhmTextField
        required
        disabled={disabled}
        id="postalCode"
        name="postalCode"
        label={t(translationKeys.FORM_SECTION_ADDRESS_ZIP)}
        value={formik.values.postalCode}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.postalCode && Boolean(formik.errors.postalCode)}
        helperText={formik.touched.postalCode && formik.errors.postalCode}
      />
      <CountrySelectInput disabled={disabled} />
      <StateSelectInput
        disabled={disabled}
        isUSCountry={formik.values.country === "US"}
      />
    </ThreeColsInputsContainer>
  );
}

export default AddressInputs;
