import { WhmSkeleton, WhmStack } from "@securitize/reactjs-whm";
import FormBlockSkeleton from "./FormBlock.skeleton";

function W8BENEFormSkeleton() {
  return (
    <FormBlockSkeleton>
      <WhmStack spacing={2}>
        <WhmSkeleton variant="rounded" width="100%" height={56} />
      </WhmStack>
    </FormBlockSkeleton>
  );
}

export default W8BENEFormSkeleton;
