import { WhmCard, WhmCardContent, WhmSkeleton } from "@securitize/reactjs-whm";

function MobileCardSkeleton() {
  return (
    <WhmCard sx={{ marginBottom: "6px" }}>
      <WhmCardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          paddingBottom: "16px !important",
        }}
      >
        <WhmSkeleton
          variant="rounded"
          width="100%"
          height="30px"
          sx={{ marginBottom: "1rem" }}
        />
        <WhmSkeleton variant="rounded" width="100%" height="30px" />
      </WhmCardContent>
    </WhmCard>
  );
}

export default MobileCardSkeleton;
