import { WhmFlexDataGrid, WhmSkeleton } from "@securitize/reactjs-whm";
import Translate from "../../../components/Translation/Translate";
import useTranslations from "../../../hooks/useTranslations";
import { translationKeys } from "../../../contexts/I18nContext"; // Import the new component
import MobileCardSkeleton from "./MobileCard.skeleton";

const columnProps = {
  disableReorder: true,
  disableColumnMenu: true,
  sortable: false,
  hideSortIcons: true,
  renderCell: () => (
    <WhmSkeleton variant="rounded" sx={{ marginTop: "1rem" }} />
  ),
};

export const DataGridSkeleton = () => {
  const { getTranslation: t } = useTranslations();

  const rowsPerPageOptions = [10, 25, 50];
  const columnsVisibility = { id: false };
  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      renderCell: () => <WhmSkeleton variant="rounded" />,
    },
    {
      field: "showDate",
      headerName: t(translationKeys.GRID_HEADER_DATE),
      type: "string",
      flex: 1,
      ...columnProps,
    },
    {
      field: "showType",
      headerName: t(translationKeys.GRID_HEADER_DOCUMENT_TYPE),
      flex: 1,
      ...columnProps,
    },
    {
      field: "relatedWith",
      headerName: t(translationKeys.GRID_HEADER_RELATED_WITH),
      flex: 1,
      ...columnProps,
    },
    {
      field: "download",
      headerName: "",
      width: 20,
      ...columnProps,
    },
    {
      field: "actions",
      headerName: "",
      width: 20,
      ...columnProps,
    },
  ];

  return (
    <WhmFlexDataGrid
      localeText={{
        MuiTablePagination: {
          labelRowsPerPage: (
            <Translate
              translationKey={
                translationKeys.GRID_PAGINATOR_LABEL_ROWS_PER_PAGE
              }
            />
          ),
          labelDisplayedRows: ({ from, to, count }) =>
            // eslint-disable-next-line max-len
            `${from} - ${to} ${t(
              translationKeys.GRID_PAGINATOR_LABEL_DISPLAYED_ROWS,
            )} ${
              count === -1
                ? `${t(
                    translationKeys.GRID_PAGINATOR_LABEL_DISPLAYED_ROWS_MORE,
                  )} ${to}`
                : count
            }`,
        },
      }}
      sx={{
        border: 0,
        ".MuiDataGrid-columnSeparator": { display: "none" },
      }}
      disableColumnMenu
      disableColumnResize
      // @ts-ignore
      columns={columns}
      // @ts-ignore
      rows={Array.from({ length: 5 }).map((_, ix) => ({ id: ix }))}
      rowsPerPageOptions={rowsPerPageOptions}
      clientSide={true}
      initialPage={0}
      initialPageSize={rowsPerPageOptions[1]}
      columnsVisibility={columnsVisibility}
      checkboxSelection={false}
      disableRowSelectionOnClick={true}
      CardComponent={() => <MobileCardSkeleton />}
    />
  );
};
