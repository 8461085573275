import {
  WhmFormControl,
  WhmInputLabel,
  WhmSelect,
  WhmMenuItem,
  WhmTextField,
} from "@securitize/reactjs-whm";
import { US_STATES } from "./constants";
import { useFormikContext } from "formik";
import useTranslations from "../../../../hooks/useTranslations";
import { translationKeys } from "../../../../contexts/I18nContext";

type Props = {
  inputName?: string;
  inputLabel?: string;
  disabled?: boolean;
  isUSCountry?: boolean;
};

function StateSelectInput({
  inputName = "region",
  inputLabel,
  disabled,
  isUSCountry = false,
}: Props) {
  const { getTranslation: t } = useTranslations();
  const formik = useFormikContext<{ [key: string]: string }>();
  const label = inputLabel || t(translationKeys.FORM_SECTION_ADDRESS_STATE);
  return (
    <WhmFormControl>
      {isUSCountry ? (
        <>
          <WhmInputLabel
            id={inputName}
            error={
              formik.touched[inputName] && Boolean(formik.errors[inputName])
            }
          >
            {label}
          </WhmInputLabel>
          <WhmSelect
            disabled={disabled}
            labelId={inputName}
            label={label}
            id={inputName}
            name={inputName}
            value={formik.values[inputName]}
            onBlur={formik.handleBlur}
            onChange={(e) => formik.setFieldValue(inputName, e.target.value)}
          >
            {Object.entries(US_STATES).map(([key, value]) => (
              <WhmMenuItem key={key} value={key}>
                {value}
              </WhmMenuItem>
            ))}
          </WhmSelect>
        </>
      ) : (
        <WhmTextField
          id={inputName}
          name={inputName}
          label={inputLabel || t(translationKeys.FORM_SECTION_ADDRESS_STATE)}
          value={formik.values[inputName]}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched[inputName] && Boolean(formik.errors[inputName])}
          helperText={formik.touched[inputName] && formik.errors[inputName]}
        />
      )}
    </WhmFormControl>
  );
}

export default StateSelectInput;
