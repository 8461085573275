import {
  WhmFormControl,
  WhmInputLabel,
  WhmMenuItem,
  WhmSelect,
  WhmStack,
  WhmTextField,
  WhmTypography,
} from "@securitize/reactjs-whm";
import { SHARED } from "@securitize/domain";
import { useFormikContext } from "formik";
import { TwoColsInputsContainer } from "../InputContainers";
import { obfuscateString } from "../../../../common/string.utils";
import useTranslations from "../../../../hooks/useTranslations";
import { translationKeys } from "../../../../contexts/I18nContext";
import { InvestorInfo } from "../../../../types/investorInformation.interface";

type Props = {
  disabled?: boolean;
  investorInfo: InvestorInfo;
};

function W8BENTaxInformationInputs({ disabled, investorInfo }: Props) {
  const { getTranslation: t } = useTranslations();
  const formik = useFormikContext<{
    USTaxPayerId: string;
    foreignTaxPayerId: string;
  }>();

  const investorForeignTaxInfo =
    investorInfo?.tax?.filter(
      (taxInfo) => !SHARED.isUsTerritory(taxInfo.taxCountryCode ?? ""),
    ) ?? [];
  const hasMultipleTax = investorForeignTaxInfo.length > 1;

  return (
    <TwoColsInputsContainer direction="row">
      <WhmStack direction="column">
        <WhmTextField
          disabled={disabled}
          id="USTaxPayerId"
          name="USTaxPayerId"
          label={t(
            translationKeys.FORM_W8BEN_SECTION_TAX_INFORMATION_US_TAX_ID,
          )}
          value={obfuscateString(formik.values.USTaxPayerId)}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.USTaxPayerId && Boolean(formik.errors.USTaxPayerId)
          }
          helperText={formik.touched.USTaxPayerId && formik.errors.USTaxPayerId}
        />
        <WhmTypography
          variant="caption"
          sx={(theme) => ({
            color: theme.palette.text.secondary,
            marginTop: "0.25rem",
          })}
        >
          {t(translationKeys.FORM_SECTION_TAX_INFORMATION_TAX_ID_CALLOUT)}
        </WhmTypography>
      </WhmStack>
      <WhmStack direction="column">
        {hasMultipleTax ? (
          <WhmFormControl>
            <WhmInputLabel
              id="foreignTaxPayerId"
              error={
                formik.touched.foreignTaxPayerId &&
                Boolean(formik.errors.foreignTaxPayerId)
              }
            >
              {t(
                translationKeys.FORM_W8BEN_SECTION_TAX_INFORMATION_FOREIGN_TAX_ID,
              )}
            </WhmInputLabel>
            <WhmSelect
              id="foreignTaxPayerId"
              name="foreignTaxPayerId"
              labelId="foreignTaxPayerId"
              label={t(
                translationKeys.FORM_W8BEN_SECTION_TAX_INFORMATION_FOREIGN_TAX_ID,
              )}
              value={formik.values.foreignTaxPayerId}
              onBlur={formik.handleBlur}
              onChange={(e) => {
                formik.setFieldValue("foreignTaxPayerId", e.target.value);
                formik.setFieldValue(
                  "taxCountry",
                  investorInfo.tax.find((tax) => tax.taxId === e.target.value)
                    ?.taxCountryCode ?? "",
                );
              }}
            >
              {investorForeignTaxInfo.map((taxInfo) => (
                <WhmMenuItem key={taxInfo.taxId} value={taxInfo.taxId}>
                  {taxInfo.taxId}
                </WhmMenuItem>
              ))}
            </WhmSelect>
          </WhmFormControl>
        ) : (
          <WhmTextField
            disabled={disabled}
            id="foreignTaxPayerId"
            name="foreignTaxPayerId"
            label={t(
              translationKeys.FORM_W8BEN_SECTION_TAX_INFORMATION_FOREIGN_TAX_ID,
            )}
            value={obfuscateString(formik.values.foreignTaxPayerId)}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.foreignTaxPayerId &&
              Boolean(formik.errors.foreignTaxPayerId)
            }
            helperText={
              formik.touched.foreignTaxPayerId &&
              formik.errors.foreignTaxPayerId
            }
          />
        )}
        <WhmTypography
          variant="caption"
          sx={(theme) => ({
            color: theme.palette.text.secondary,
            marginTop: "0.25rem",
          })}
        >
          {t(
            translationKeys.FORM_SECTION_TAX_INFORMATION_FOREIGN_TAX_ID_CALLOUT,
          )}
        </WhmTypography>
      </WhmStack>
    </TwoColsInputsContainer>
  );
}

export default W8BENTaxInformationInputs;
