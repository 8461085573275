import { WhmSkeleton, WhmStack } from "@securitize/reactjs-whm";
import FormBlockSkeleton from "./FormBlock.skeleton";
import {
  ThreeColsInputsContainer,
  TwoColsInputsContainer,
} from "./InputContainers";

function SkeletonBatch({ amount }: { amount: number }) {
  return (
    <>
      {Array.from({ length: amount }, (_, i) => (
        <WhmSkeleton key={i} variant="rounded" width="100%" height={56} />
      ))}
    </>
  );
}

function W9FormSkeleton() {
  return (
    <WhmStack spacing={6}>
      {/* Personal Information */}
      <FormBlockSkeleton>
        <WhmSkeleton variant="rounded" width="100%" height={56} />
      </FormBlockSkeleton>
      {/* Address */}
      <FormBlockSkeleton>
        <ThreeColsInputsContainer
          sx={{
            gridTemplateRows: {
              xs: "unset",
              md: '"1fr 1fr"',
            },
          }}
        >
          <SkeletonBatch amount={6} />
        </ThreeColsInputsContainer>
      </FormBlockSkeleton>
      {/* Tax Information */}
      <FormBlockSkeleton>
        <ThreeColsInputsContainer direction="row">
          <SkeletonBatch amount={3} />
        </ThreeColsInputsContainer>
      </FormBlockSkeleton>
      {/* Exemptions */}
      <FormBlockSkeleton>
        <TwoColsInputsContainer direction="row">
          <SkeletonBatch amount={2} />
        </TwoColsInputsContainer>
      </FormBlockSkeleton>
      {/* Certification */}
      <FormBlockSkeleton>
        <WhmSkeleton
          variant="rounded"
          width="100%"
          height={56}
          sx={{
            marginBottom: "0.5rem",
          }}
        />
        <TwoColsInputsContainer direction="row">
          <SkeletonBatch amount={2} />
        </TwoColsInputsContainer>
      </FormBlockSkeleton>
    </WhmStack>
  );
}

export default W9FormSkeleton;
