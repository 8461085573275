import React from "react";
import {
  WhmCard,
  WhmTypography,
  WhmCardContent,
  WhmTag,
  WhmBox,
} from "@securitize/reactjs-whm";
import { GridMenu } from "./GridMenu";
import { DocumentsRecord } from "../../../types/documents.interface";
import Translate, {
  TranslateProps,
} from "../../../components/Translation/Translate";
import useTranslations from "../../../hooks/useTranslations";

export const CardComponent = ({
  row,
  loadServerRows,
}: {
  row: DocumentsRecord;
  loadServerRows: () => void;
}) => {
  const { getTranslation: t } = useTranslations();

  const date = (value: string | TranslateProps) => {
    if (typeof value === "string") {
      return value;
    } else {
      return (
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        <Translate
          translationKey={value.translationKey}
          replacements={value.replacements}
        />
      );
    }
  };

  return (
    <WhmCard sx={{ marginBottom: "6px" }}>
      <WhmCardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          paddingBottom: "16px !important",
        }}
      >
        <WhmBox
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "6px",
          }}
        >
          <WhmTypography variant="body2">{row.showType}</WhmTypography>
          <WhmBox>
            <GridMenu
              row={row}
              loadServerRows={loadServerRows}
              isMobile={true}
            />
          </WhmBox>
        </WhmBox>
        <WhmBox
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <WhmTypography variant="body2" color="text.secondary">
            {date(row.showDate)}
          </WhmTypography>
          <WhmTag colorVariant={"default"} label={t(row.relatedWith)} />
        </WhmBox>
      </WhmCardContent>
    </WhmCard>
  );
};
