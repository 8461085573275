import { useState } from "react";
import {
  WhmFormControl,
  WhmInputLabel,
  WhmMenuItem,
  WhmSelect,
  WhmStack,
  WhmTypography,
} from "@securitize/reactjs-whm";
import { useFormikContext } from "formik";
import { TwoColsInputsContainer } from "../InputContainers";
import ExemptPayeeCodesModal from "../ExemptCodesModal";
import useTranslations from "../../../../hooks/useTranslations";
import { translationKeys } from "../../../../contexts/I18nContext";
import useExemptionCodes from "../../../../hooks/useExemptionCodes";

function ExemptionsInputs() {
  const [openExemptDialogWithVariant, setOpenExemptDialogWithVariant] =
    useState<"PAYEE" | "FATCA" | undefined>(undefined);
  const { getTranslation: t } = useTranslations();
  const { codesListResolver, itemIndexResolver } = useExemptionCodes();
  const formik = useFormikContext<{
    exemptPayeeCodes: string;
    exemptFATCAReportingCodes: string;
  }>();

  return (
    <>
      <TwoColsInputsContainer direction="row">
        <WhmStack direction="column">
          <WhmFormControl>
            <WhmInputLabel
              id="exemptPayeeCodes"
              error={
                formik.touched.exemptPayeeCodes &&
                Boolean(formik.errors.exemptPayeeCodes)
              }
            >
              {t(translationKeys.FORM_SECTION_EXEMPTIONS_PAYEE)}
            </WhmInputLabel>
            <WhmSelect
              labelId="exemptPayeeCodes"
              label={t(translationKeys.FORM_SECTION_EXEMPTIONS_PAYEE)}
              id="exemptPayeeCodes"
              name="exemptPayeeCodes"
              value={formik.values.exemptPayeeCodes}
              onBlur={formik.handleBlur}
              onChange={(e) =>
                formik.setFieldValue("exemptPayeeCodes", e.target.value)
              }
            >
              {codesListResolver["PAYEE"].map((code, index) => {
                const prefix = itemIndexResolver["PAYEE"](index);
                const label = `${prefix}. ${code}`;
                return (
                  <WhmMenuItem value={prefix} key={prefix}>
                    {label.length > 50 ? label.substring(0, 50) + "..." : label}
                  </WhmMenuItem>
                );
              })}
            </WhmSelect>
          </WhmFormControl>
          <WhmTypography
            variant="body1"
            sx={(theme) => ({
              color: theme.palette.primary.main,
              cursor: "pointer",
            })}
            onClick={() => setOpenExemptDialogWithVariant("PAYEE")}
          >
            {t(translationKeys.FORM_SECTION_EXEMPTIONS_PAYEE_CTA)}
          </WhmTypography>
        </WhmStack>
        <WhmStack direction="column" spacing={2}>
          <WhmFormControl>
            <WhmInputLabel
              id="exemptFATCAReportingCodes"
              error={
                formik.touched.exemptFATCAReportingCodes &&
                Boolean(formik.errors.exemptFATCAReportingCodes)
              }
            >
              {t(translationKeys.FORM_SECTION_EXEMPTIONS_FATCA)}
            </WhmInputLabel>
            <WhmSelect
              labelId="exemptFATCAReportingCodes"
              label={t(translationKeys.FORM_SECTION_EXEMPTIONS_FATCA)}
              id="exemptFATCAReportingCodes"
              name="exemptFATCAReportingCodes"
              value={formik.values.exemptFATCAReportingCodes}
              onBlur={formik.handleBlur}
              onChange={(e) =>
                formik.setFieldValue(
                  "exemptFATCAReportingCodes",
                  e.target.value,
                )
              }
            >
              {codesListResolver["FATCA"].map((code, index) => {
                const prefix = itemIndexResolver["FATCA"](index);
                const label = `${prefix}. ${code}`;
                return (
                  <WhmMenuItem value={prefix} key={prefix}>
                    {label.length > 50 ? label.substring(0, 50) + "..." : label}
                  </WhmMenuItem>
                );
              })}
            </WhmSelect>
          </WhmFormControl>
          <WhmTypography
            variant="body1"
            sx={(theme) => ({
              color: theme.palette.primary.main,
              cursor: "pointer",
            })}
            onClick={() => setOpenExemptDialogWithVariant("FATCA")}
          >
            {t(translationKeys.FORM_SECTION_EXEMPTIONS_FATCA_CTA)}
          </WhmTypography>
        </WhmStack>
      </TwoColsInputsContainer>
      {openExemptDialogWithVariant && (
        <ExemptPayeeCodesModal
          variant={openExemptDialogWithVariant}
          open={Boolean(openExemptDialogWithVariant)}
          onClose={() => setOpenExemptDialogWithVariant(undefined)}
        />
      )}
    </>
  );
}

export default ExemptionsInputs;
