import {
  WhmFormControl,
  WhmInputLabel,
  WhmSelect,
  WhmMenuItem,
  WhmTextField,
  WhmStack,
  WhmTypography,
} from "@securitize/reactjs-whm";
import { useFormikContext } from "formik";
import { ThreeColsInputsContainer } from "../InputContainers";
import { InvestorType, TaxIdType } from "../../../../types/documents.interface";
import useTranslations from "../../../../hooks/useTranslations";
import { translationKeys } from "../../../../contexts/I18nContext";

type Props = {
  disabled?: boolean;
};

function W9TaxInformationInputs({ disabled }: Props) {
  const { getTranslation: t } = useTranslations();
  const formik = useFormikContext<{
    formClassification: InvestorType;
    taxPayerIdType: string;
    taxPayerIdNumber: string;
  }>();

  return (
    <ThreeColsInputsContainer direction="row">
      <WhmFormControl>
        <WhmInputLabel
          id="formClassification"
          error={
            formik.touched.formClassification &&
            Boolean(formik.errors.formClassification)
          }
        >
          {t(translationKeys.FORM_SECTION_TAX_INFORMATION_CLASSIFICATION)}
        </WhmInputLabel>
        <WhmSelect
          disabled={disabled}
          labelId="formClassification"
          label={t(translationKeys.FORM_SECTION_TAX_INFORMATION_CLASSIFICATION)}
          id="formClassification"
          name="formClassification"
          value={formik.values.formClassification}
          onBlur={formik.handleBlur}
          onChange={(e) =>
            formik.setFieldValue("formClassification", e.target.value)
          }
        >
          <WhmMenuItem value={InvestorType.ENTITY}>
            {t(
              translationKeys.FORM_SECTION_TAX_INFORMATION_CLASSIFICATION_ENTITY,
            )}
          </WhmMenuItem>
          <WhmMenuItem value={InvestorType.INDIVIDUAL}>
            {t(
              translationKeys.FORM_SECTION_TAX_INFORMATION_CLASSIFICATION_INDIVIDUAL,
            )}
          </WhmMenuItem>
        </WhmSelect>
      </WhmFormControl>
      <WhmFormControl>
        <WhmInputLabel
          id="taxPayerIdType"
          error={
            formik.touched.taxPayerIdType &&
            Boolean(formik.errors.taxPayerIdType)
          }
        >
          {t(translationKeys.FORM_SECTION_TAX_INFORMATION_TAX_IDENTIFIER)}
        </WhmInputLabel>
        <WhmSelect
          disabled={disabled}
          labelId="taxPayerIdType"
          label={t(translationKeys.FORM_SECTION_TAX_INFORMATION_TAX_IDENTIFIER)}
          id="taxPayerIdType"
          name="taxPayerIdType"
          value={formik.values.taxPayerIdType}
          onBlur={formik.handleBlur}
          onChange={(e) =>
            formik.setFieldValue("taxPayerIdType", e.target.value)
          }
        >
          <WhmMenuItem value={TaxIdType.SSN}>
            {t(translationKeys.FORM_SECTION_TAX_INFORMATION_TAX_IDENTIFIER_SSN)}
          </WhmMenuItem>
          <WhmMenuItem value={TaxIdType.FOREIGN}>
            {t(
              translationKeys.FORM_SECTION_TAX_INFORMATION_TAX_IDENTIFIER_FOREIGN,
            )}
          </WhmMenuItem>
        </WhmSelect>
      </WhmFormControl>
      <WhmStack direction="column">
        <WhmTextField
          disabled={disabled}
          id="taxPayerIdNumber"
          name="taxPayerIdNumber"
          label={t(
            translationKeys.FORM_W8BEN_SECTION_TAX_INFORMATION_US_TAX_ID,
          )}
          value={formik.values.taxPayerIdNumber}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.taxPayerIdNumber &&
            Boolean(formik.errors.taxPayerIdNumber)
          }
          helperText={
            formik.touched.taxPayerIdNumber && formik.errors.taxPayerIdNumber
          }
        />
        <WhmTypography
          variant="body2"
          sx={(theme) => ({ color: theme.palette.text.secondary })}
        >
          {t(translationKeys.FORM_SECTION_TAX_INFORMATION_TAX_ID_CALLOUT)}
        </WhmTypography>
      </WhmStack>
    </ThreeColsInputsContainer>
  );
}

export default W9TaxInformationInputs;
