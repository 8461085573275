import { BoxOwnProps, Theme } from "@mui/system";
import { WhmBox, WhmTypography } from "@securitize/reactjs-whm";
import React from "react";

type Props = {
  title: string | React.ReactNode;
  description?: string | React.ReactNode;
  children: React.ReactNode;
  boxSx?: BoxOwnProps<Theme>["sx"];
};

function FormBlock({ title, description, children, boxSx = {} }: Props) {
  return (
    <WhmBox
      sx={{
        margin: "5px",
        padding: "1.5rem",
        width: "100%",
        background: "white",
        borderRadius: "5px",
        ...(boxSx as object),
      }}
    >
      {typeof title === "string" ? (
        <WhmTypography
          variant="h5"
          sx={{
            fontWeight: 500,
          }}
        >
          {title}
        </WhmTypography>
      ) : (
        title
      )}
      {Boolean(description) &&
        (typeof description === "string" ? (
          <WhmTypography
            variant="body2"
            sx={(theme) => ({
              marginBottom: "2rem",
              marginTop: "0.5rem",
              color: theme.palette.text.secondary,
            })}
          >
            {description}
          </WhmTypography>
        ) : (
          description
        ))}
      {children}
    </WhmBox>
  );
}

export default FormBlock;
