import { WhmSkeleton, WhmStack } from "@securitize/reactjs-whm";
import FormBlockSkeleton from "./FormBlock.skeleton";
import {
  ThreeColsInputsContainer,
  TwoColsInputsContainer,
} from "./InputContainers";

function SkeletonBatch({ amount }: { amount: number }) {
  return (
    <>
      {Array.from({ length: amount }, (_, i) => (
        <WhmSkeleton key={i} variant="rounded" width="100%" height={56} />
      ))}
    </>
  );
}

function W8BENFormSkeleton() {
  return (
    <WhmStack spacing={6}>
      {/* Personal Information */}
      <FormBlockSkeleton>
        <ThreeColsInputsContainer>
          <SkeletonBatch amount={3} />
        </ThreeColsInputsContainer>
      </FormBlockSkeleton>
      {/* Address */}
      <FormBlockSkeleton>
        <ThreeColsInputsContainer>
          <SkeletonBatch amount={6} />
        </ThreeColsInputsContainer>
      </FormBlockSkeleton>
      {/* Mailing Address */}
      <FormBlockSkeleton>
        <WhmSkeleton
          variant="text"
          width="100%"
          sx={{
            marginBottom: "0.5rem",
          }}
        />
        <ThreeColsInputsContainer>
          <SkeletonBatch amount={6} />
        </ThreeColsInputsContainer>
      </FormBlockSkeleton>
      {/* Tax information */}
      <FormBlockSkeleton>
        <TwoColsInputsContainer>
          <SkeletonBatch amount={2} />
        </TwoColsInputsContainer>
      </FormBlockSkeleton>
      {/* Exemptions */}
      <FormBlockSkeleton>
        <TwoColsInputsContainer direction="row">
          <SkeletonBatch amount={2} />
        </TwoColsInputsContainer>
      </FormBlockSkeleton>
      {/* Certification */}
      <FormBlockSkeleton>
        <WhmSkeleton
          variant="rounded"
          width="100%"
          height={56}
          sx={{
            marginBottom: "0.5rem",
          }}
        />
        <TwoColsInputsContainer direction="row">
          <SkeletonBatch amount={2} />
        </TwoColsInputsContainer>
      </FormBlockSkeleton>
    </WhmStack>
  );
}

export default W8BENFormSkeleton;
