import {
  WhmButton,
  WhmDialog,
  WhmDialogActions,
  WhmDialogContent,
  WhmDialogTitle,
  WhmLoadingButton,
  WhmTypography,
} from "@securitize/reactjs-whm";
import { useState } from "react";
import useTranslations from "../../../hooks/useTranslations";
import { translationKeys } from "../../../contexts/I18nContext";

type Props = {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void | Promise<void>;
  onCancel: () => void;
  onError?: (error: any) => void;
};

function FormConfirmationModal({
  open,
  onClose,
  onConfirm,
  onCancel,
  onError,
}: Props) {
  const [loading, setLoading] = useState(false);
  const { getTranslation: t } = useTranslations();

  const handleConfirm = async () => {
    setLoading(true);
    try {
      await onConfirm();
    } catch (error) {
      onError?.(error);
    }
    setLoading(false);
    onClose();
  };

  return (
    <WhmDialog
      open={open}
      onClose={onClose}
      title={t(translationKeys.FORM_MODAL_SUBMIT_CONFIRMATION_TITLE)}
    >
      <WhmDialogTitle>
        <WhmTypography variant="h4">
          {t(translationKeys.FORM_MODAL_SUBMIT_CONFIRMATION_SUBTITLE)}
        </WhmTypography>
      </WhmDialogTitle>
      <WhmDialogContent>
        <WhmTypography variant="body2">
          {t(translationKeys.FORM_MODAL_SUBMIT_CONFIRMATION_DESCRIPTION)}
        </WhmTypography>
      </WhmDialogContent>
      <WhmDialogActions>
        <WhmButton variant="outlined" onClick={onCancel} disabled={loading}>
          {t(translationKeys.WORDS_CANCEL)}
        </WhmButton>
        <WhmLoadingButton
          autoFocus
          variant="contained"
          loading={loading}
          loadingPosition="start"
          // eslint-disable-next-line
          onClick={handleConfirm}
          sx={{
            ".MuiCircularProgress-root": { display: "flex" },
          }}
        >
          {t(translationKeys.WORDS_SUBMIT)}
        </WhmLoadingButton>
      </WhmDialogActions>
    </WhmDialog>
  );
}

export default FormConfirmationModal;
